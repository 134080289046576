import http from "@/api/http";

class StorageService {
  get(fields?: string) {
    return http.get("/storage", {
      params: {
        select: fields
      }
    });
  }

  create(data: object) {
    return http.post("/storage", data);
  }
}

export default new StorageService();
