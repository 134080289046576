

































import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import StorageService from "@/api/storage";
import { mapGetters } from "vuex";

@Component({
  computed: mapGetters("UserModule", {
    active: "getStorage"
  })
})
export default class ChangeStorage extends Vue {
  storages: any = [];
  storage = 0;
  active!: any;
  @Prop(Object) readonly chooseLogin: any;

  @Emit()
  close() {
    return false;
  }

  changeStorage() {
    if (this.storage != 0) {
      this.$store.dispatch(
        "UserModule/setStorage",
        this.storages.find((x: any) => x.id == this.storage)
      );
      this.$buefy.notification.open({
        message: "El almacen activo ha cambiado",
        type: "is-success"
      });
      this.$emit("close");
    }
  }

  mounted() {
    StorageService.get("alias,id").then(response => {
      this.storages = response.data.own.concat(
        response.data.member_of.map((x: any) => x.storage)
      );
    });
    this.storage = this.active ? this.active.id : 0;
  }
}
