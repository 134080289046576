



























































































































import { Component, Vue } from "vue-property-decorator";
import StorageService from "@/api/storage";
import ChangeStorage from "@/components/storages/ChangeStorage.vue";
import { mapGetters } from "vuex";
interface Storage {
  id: string;
  alias: string;
}
@Component({
  components: {
    ChangeStorage
  },
  computed: {
    ...mapGetters("UserModule", {
      storageActive: "getStorage"
    })
  }
})
export default class Home extends Vue {
  owned = [];
  memberOf: Storage[] = [];
  loaded = false;
  storage = {
    id: "",
    alias: ""
  };
  storageActive!: any;
  bodega = {
    alias: ""
  };
  noStorageSelected = true;

  openModal(type: string) {
    this.$store.dispatch(type, true);
  }

  crearBodega() {
    if (this.bodega.alias == "") {
      this.$buefy.notification.open({
        message: "El alias no puede estar vacio",
        type: "is-danger"
      });
    }
    StorageService.create(this.bodega)
      .then(response => {
        this.$store.dispatch("UserModule/setStorage", response.data);
        this.$router.push({ name: "Nueces" });
      })
      .catch(() =>
        this.$buefy.notification.open({
          message: "Ha ocurrido un error al crear la bodega",
          type: "is-danger"
        })
      );
  }

  changeStorage() {
    if (this.storage.id != "") {
      this.$store.dispatch(
        "UserModule/setStorage",
        this.memberOf.find((x: any) => x.id == this.storage)
      );
      this.$buefy.notification.open({
        message: "El almacen activo ha cambiado",
        type: "is-success"
      });
      this.$router.push({ name: "Nueces" });
    }
  }

  mounted() {
    StorageService.get().then(response => {
      this.owned = response.data.own;
      this.memberOf = response.data.member_of.map((x: any) => x.storage);
      this.loaded = true;
    });
    // if (this.storageActive) {
    //   this.$router.push({ name: "Nueces" });
    // }
  }
}
